export default defineNuxtRouteMiddleware((to) => {
  // If the query param 'embeddedLink' exists,
  // - navigate to the route
  // - AND open the ordering panel with any query params
  if (Object.hasOwn(to.query, 'embeddedLink')) {
    const { orderingAppNavToEmbeddedRoute } = useOrderingAppNavigation()
    // Iterate through the query params and create a string to pass to the ordering app
    const path = Object.entries(to.query).reduce((acc, [key, value]) => {
      if (key === 'embeddedLink') return acc
      return `${acc}&${key}=${value}`
    }, to.query.embeddedLink as string)

    orderingAppNavToEmbeddedRoute(path)
  }
})
