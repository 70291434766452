/**
 * @description Error handlers plugin to pipe to Sentry
 */
export default defineNuxtPlugin((nuxtApp) => {
  const { $sentryCaptureException } = useNuxtApp()

  nuxtApp.hook('vue:error', (error) => {
    if ($sentryCaptureException) $sentryCaptureException(error)
  })
})
