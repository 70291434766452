<script lang="ts" setup>
  // Nuxt's internal error object
  import type { H3Error } from 'h3'

  const { hasPanelMounted } = useOrderingPanelState()
  hasPanelMounted.value = false

  const props = defineProps<{
    error: H3Error
  }>()

  const errorMessage = computed(() =>
    props.error.statusCode === 404 ? `404<br/>Page not Found` : 'Oops, something<br/> went wrong'
  )
</script>

<template>
  <div>
    <NuxtLayout name="default">
      <div class="container">
        <icon-grilld-error alt="Error" class="error-image" />
        <h1 class="header-3 message" v-html="errorMessage" />
        <nuxt-link href="/">
          <CButton>Return to Grill'd</CButton>
        </nuxt-link>
      </div>
    </NuxtLayout>
  </div>
</template>

<style lang="scss">
  .container {
    margin: 13vh auto 13vh auto;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .error-image {
    width: 307px;
    margin-bottom: 32px;
  }

  .message {
    color: var(--color-secondary-red-dark);
    margin-bottom: 16px;
  }
</style>
