import type { LocationQuery } from 'vue-router'

/**
 * @description Append UTM codes and click IDs from query to the route.
 * @param route {string} The route to transform
 * @returns {string} The updated route
 */
export function appendQueryParameters(route: string, query: LocationQuery): string {
  // filter the query object to only include utm codes
  query = Object.keys(query).reduce((acc, key) => {
    if (key.toLowerCase().startsWith('utm_') || key.toLowerCase() === 'fbclid' || key.toLowerCase() === 'ttclid') {
      acc[key] = query[key]
    }
    return acc
  }, {} as LocationQuery)

  // iterate over the query object and append each key-value pair as a utm code
  for (const [key, value] of Object.entries(query)) {
    route += `&${key}=${value}`
  }
  return route
}
