import * as Sentry from '@sentry/vue'
import { version } from '@@/package.json'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  const {
    public: { deployEnv, sentry }
  } = useRuntimeConfig()

  // If no sentry DSN set, ignore and warn in the console
  if (!sentry.dsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  // prevent Sentry on local dev or if misconfigured
  if (deployEnv === 'local') {
    console.warn('Skipping Sentry initialization in local environment')
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: deployEnv === 'production' ? 'production' : 'nonProd',
    release: 'grilld-web-client@' + version,
    dist: '1',
    integrations: [
      Sentry.browserTracingIntegration({
        router
      })
    ],
    tracesSampleRate: deployEnv === 'production' ? 0.25 : 1.0
  })

  nuxtApp.vueApp.mixin(
    Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] })
  )
  Sentry.attachErrorHandler(nuxtApp.vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
