import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as indexhDiKiixGwtMeta } from "/app/src/pages/group-bookings/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93wvSy7tRLQRMeta } from "/app/src/pages/restaurants/[...slug].vue?macro=true";
import { default as indexfqjrnnEz67Meta } from "/app/src/pages/restaurants/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexhDiKiixGwtMeta?.name ?? "group-bookings",
    path: indexhDiKiixGwtMeta?.path ?? "/group-bookings",
    meta: indexhDiKiixGwtMeta || {},
    alias: indexhDiKiixGwtMeta?.alias || [],
    redirect: indexhDiKiixGwtMeta?.redirect,
    component: () => import("/app/src/pages/group-bookings/index.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wvSy7tRLQRMeta?.name ?? "restaurants-slug",
    path: _91_46_46_46slug_93wvSy7tRLQRMeta?.path ?? "/restaurants/:slug(.*)*",
    meta: _91_46_46_46slug_93wvSy7tRLQRMeta || {},
    alias: _91_46_46_46slug_93wvSy7tRLQRMeta?.alias || [],
    redirect: _91_46_46_46slug_93wvSy7tRLQRMeta?.redirect,
    component: () => import("/app/src/pages/restaurants/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexfqjrnnEz67Meta?.name ?? "restaurants",
    path: indexfqjrnnEz67Meta?.path ?? "/restaurants",
    meta: indexfqjrnnEz67Meta || {},
    alias: indexfqjrnnEz67Meta?.alias || [],
    redirect: indexfqjrnnEz67Meta?.redirect,
    component: () => import("/app/src/pages/restaurants/index.vue").then(m => m.default || m)
  }
]