<script lang="ts" setup>
  import { SITE_ENVIRONMENTS } from './constants/siteEnvironment'

  type LayoutName = 'with-ordering-app' | 'default'

  const $config = useRuntimeConfig()

  const layout: LayoutName =
    new Map<string, LayoutName>([
      [SITE_ENVIRONMENTS.AUSTRALIA, 'with-ordering-app'],
      [SITE_ENVIRONMENTS.INDONESIA, 'default']
    ]).get($config.public.siteEnvironment) || 'default'
</script>

<template>
  <NuxtLayout :name="layout">
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
  @import '@/styles/main.scss';

  body {
    background: var(--color-secondary-paper-lightest);
    font-family: var(--font-family-base);
  }
</style>
